<template>
  <div class="product-feeds__page-create">
    <v-row no-gutters>
      <v-col cols="12" class="page-header">
        <redirect class="mb-5" title="Product Feeds" route="website.product-feeds" />
        <h1 class="page-title">Add product feed</h1>
        <v-divider class="my-5"></v-divider>
      </v-col>
      <v-col cols="12" v-if="isLoading">
        <SkeletonLoaders type="card-heading, list-item-three-line" />
      </v-col>
      <v-col v-else cols="12">
        <v-row>
          <v-col cols="4">
            <h3>Settings</h3>
          </v-col>
          <v-col cols="8" class="elevation-form">
            <InputDefault label="Feed name" :model="['feed', 'name']" placeholder="Collection name - Product Feed" />
            <v-divider class="my-5"></v-divider>
            <h4>All products or just some of them?</h4>
            <v-radio-group v-model="method">
              <v-radio label="All products" value="allProducts"></v-radio>
              <v-radio label="Products from selected collections" value="Collections"></v-radio>
            </v-radio-group>
            <v-autocomplete
              v-if="method == 'Collections'"
              hide-details
              multiple
              v-model="feed.collectionsIds"
              :items="collections"
              item-text="title"
              item-value="_id"
            >
              <template v-slot:selection="data">
                <v-chip
                  v-bind="data.attrs"
                  :input-value="data.selected"
                  close
                  @click="data.select"
                  @click:close="remove(data.item)"
                >
                  {{ data.item.title }}
                </v-chip>
              </template>
            </v-autocomplete>
            <v-divider class="mb-5"></v-divider>
            <h4>Export mode</h4>
            <v-radio-group v-model="feed.exportMode" label="">
              <!-- <v-radio label="Export all variations of a product" value="all_variation"></v-radio> -->
              <v-radio label="Export only first variation of a product" value="first_variation"></v-radio>
            </v-radio-group>
            <v-divider class="mb-5"></v-divider>
            <h4>Variant title</h4>
            <v-radio-group v-model="feed.addVariantOptionToTitle" label="">
              <v-radio label="Do not add variant title at the end of the title" :value="0"></v-radio>
              <v-radio
                label="Add all options like color, size, etc behind the title (example: t-shirt red S)"
                :value="1"
              ></v-radio>
            </v-radio-group>
            <v-divider class="mb-5"></v-divider>
            <h4>Auto Update</h4>
            <p>This option is only available when your feed file contains less than 10000 products</p>
            <v-radio-group v-model="feed.autoUpdate" label="">
              <v-radio label="True" :value="true"></v-radio>
              <v-radio label="False" :value="false"></v-radio>
            </v-radio-group>
            <v-divider class="mb-5"></v-divider>
            <!-- <v-radio-group v-model="feed.is_custom_products" label="Submit products as custom products">
              <v-radio label="True" :value="true"></v-radio>
              <v-radio label="False" :value="false"></v-radio>
            </v-radio-group> -->
            <!-- <v-divider class="my-5"></v-divider> -->
            <div>
              <h4>Google product category</h4>
              <p>Required if you sell apparel and you need to set gender and age-group too</p>
              <v-autocomplete
                hide-details
                v-model="feed.googleProductCategory"
                :items="categories"
                item-text="value"
                item-value="id"
              >
              </v-autocomplete>
              <p>Looking for which product category to fit with your products? We have a guide for this.</p>
              <v-divider class="my-5"></v-divider>
            </div>
            <div>
              <h4>Default Gender</h4>
              <p>
                Required for all apparel items that are focused on people in Brazil, France, Germany, Japan, the UK, and
                the US as well as all products with assigned age groups.
              </p>
              <InputDefault
                :itemsSelect="listGender"
                itemText="name"
                itemValue="value"
                typeComponent="select"
                label=""
                :model="['feed', 'gender']"
              />
              <v-divider class="my-5"></v-divider>
            </div>
            <div>
              <h4>Default Age Group</h4>
              <p>
                Required for all apparel items in feeds that are focused on people in Brazil, France, Germany, Japan,
                the UK, and the US as well as all gender-specific products.
              </p>
              <InputDefault
                :itemsSelect="groups"
                itemText="name"
                itemValue="value"
                typeComponent="select"
                label=""
                :model="['feed', 'ageGroup']"
              />
              <v-divider class="my-5"></v-divider>
            </div>
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="12" class="d-flex align-center mt-5">
        <v-btn
          :loading="isLoadingAction"
          :disabled="isLoadingAction"
          @click="showDialogDelete = true"
          color="error"
          class=""
          >Delete</v-btn
        >
        <v-btn :loading="isLoadingAction" :disabled="isLoadingAction" @click="submit" color="primary" class="ml-auto"
          >Save</v-btn
        >
      </v-col>
    </v-row>
    <delete-action :content="feed.name" :title="'Delete ' + feed.name" />
  </div>
</template>
<script>
import { collectionApi } from '@/apis/collection';
import { productApi } from '@/apis/product';

export default {
  props: ['id'],
  data() {
    return {
      showDialogDelete: false,
      feed: {
        name: '',
        googleProductCategory: null,
        gender: 1,
        exportMode: 'all_variation',
        collectionsIds: [],
        ageGroup: null,
        addVariantOptionToTitle: 0,
        autoUpdate: false,
      },
      method: 'allProducts',
      listGender: [
        {
          name: 'Male',
          value: 'male',
        },
        {
          name: 'Female',
          value: 'female',
        },
        {
          name: 'Unisex',
          value: 'unisex',
        },
      ],
      groups: [
        {
          name: 'Newborn',
          value: 'newborn',
        },
        {
          name: 'Infant',
          value: 'infant',
        },
        {
          name: 'Toddler',
          value: 'toddler',
        },
        {
          name: 'Kids',
          value: 'kids',
        },
        {
          name: 'Adult',
          value: 'adult',
        },
      ],
      collections: [],
      categories: [],
      isLoading: false,
      isLoadingAction: false,
    };
  },
  created() {
    this.loadData();
    this.loadCollections();
    this.loadGoogleProductCategory();
  },
  methods: {
    async loadData() {
      this.isLoading = true;
      try {
        let data = await productApi.getProductFeedById(this.id);
        this.feed = data.data.productFeed;
        if (this.feed.collectionsIds && this.feed.collectionsIds.length > 0) {
          this.method = 'Collections';
        }
        this.feed.googleProductCategory = this.feed.googleProductCategory.toString();
        this.isLoading = false;
      } catch (error) {
        this.isLoading = false;
        console.log(error);
      }
    },
    async loadGoogleProductCategory() {
      try {
        let data = await productApi.getGoogleProductCategory();
        this.categories = data.data.categories;
      } catch (error) {
        console.log(error);
      }
    },
    async loadCollections() {
      try {
        let params = {
          page: 1,
          limit: 1000,
        };
        let collectionsData = await collectionApi.get(params);
        this.collections = collectionsData.data.collections;
      } catch (error) {
        console.log(error);
      }
    },
    remove(item) {
      const index = this.feed.collections_ids.indexOf(item._id);
      if (index >= 0) this.feed.collections_ids.splice(index, 1);
    },
    async submit() {
      this.isLoadingAction = true;
      try {
        let data = await productApi.updateProductFeed({ productFeed: this.feed });

        this.isLoadingAction = false;
      } catch (error) {
        this.isLoadingAction = false;
        console.log(error);
      }
    },
    async submitDelete() {
      this.isLoadingAction = true;
      try {
        let data = await productApi.deleteProductFeed({ id: this.feed._id });
        this.$router.push({ name: 'website.product-feeds' });
        this.isLoadingAction = false;
      } catch (error) {
        this.isLoadingAction = false;
      }
    },
  },
};
</script>
